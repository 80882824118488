<template>
  <section id="intro">
    <v-container class="text-center">
      <v-responsive
        class="mx-auto title font-weight-bold mb-4 intro"
        max-width="750"
      >"
        Design and Develops Modern Web applications using the latest
        technologies.<br/> Expert in every phase of Software Development, from Design to
        Deployment.<br/> Always follow the best practices to achieve better results.
        <br />
        Learning never ends... "
      </v-responsive>
    </v-container>
    <div class="py-5"></div>
  </section>
</template>

<script>
export default {};
</script>

<style>
.intro {
    color: #0d2063;
}
</style>
