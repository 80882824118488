<template>
  <v-sheet id="contact" color="transparent" tag="section" tile>
    <v-container>
      <h2 class="display-1 font-weight-bold mb-5 text-uppercase text-center">
        Contact
      </h2>
      <v-row>
        <v-col cols="12">
            <div class="text-center">
                <div class="title">
                    <a style="text-decoration: none; color: inherit;" href="mailto:jjcreation.xyz@gmail.com"><v-icon>mdi-email</v-icon> jjcreations.xyz@gmail.com</a>
                </div>
            </div>
        </v-col>
      </v-row>
    </v-container>

    <div class="py-12"></div>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      formValid: false,
      loading: false,
      form: {
        name: "",
        email: "",
        description: "",
      },
      formRule: {
        name: [(v) => !!v || "Name is Required"],
        email: [
          (v) => !!v || "Email is Required",
          (v) => /.+@.+\..+/.test(v) || "Email should be valid",
        ],
        description: [(v) => !!v || "Description is Required"],
      },
    };
  },
};
</script>

<style></style>
