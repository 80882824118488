<template>
  <v-app-bar app color="#f7faff" elevate-on-scroll height="80px">
    <v-toolbar-title>
      <v-img defer contain max-height="50%" src="jj-logo.png"></v-img>
    </v-toolbar-title>
    <v-spacer />
  </v-app-bar>
</template>

<script>
export default {};
</script>

<style></style>
