<template>
  <v-app>
    <nav-bar />
    <v-main>
      <intro />
      <technology />
      <contact />
    </v-main>

    <v-footer class="justify-center" color="transparent" height="100">
      <div class="title font-weight-light text--lighten-1 text-center">
        &copy; {{ new Date().getFullYear() }} — JJ Creations.Online
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import Contact from "./components/Contact.vue";
import Intro from "./components/Intro.vue";
import NavBar from "./components/NavBar.vue";
import Technology from "./components/Technology.vue";
export default {
  name: "App",
  components: {
    Technology,
    NavBar,
    Intro,
    Contact,
  },
};
</script>

<style>
#app {
  background: rgb(45, 129, 252);
  background: linear-gradient(
    0deg,
    rgba(45, 129, 252, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
</style>
